@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  box-sizing: inherit;
  font-family: inherit;
  margin: 0;
}

.bg-blue {
  background-color: #61dafb;
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: 'PressStart2P';
  src: url(./Fonts/PressStart2P.ttf) format('truetype');
}

body {
  /* Create room for OSX scroll bar */
  margin-right: 1em;
}

#root {
  font-family: 'PressStart2P';
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  min-height: 100vh;
  box-sizing: border-box;
}

label, button {
  cursor: pointer;
}

button[disabled] {
  cursor: context-menu;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1em 0;
}

[align="left"] {
  text-align: left;
}

[align="right"] {
  text-align: right;
}

[align="center"] {
  text-align: center;
}


.looks-like-link {
  font-size: 1em;
  background: none;
  border: none;
  text-decoration: underline;
  line-height: 1.6;
}
.looks-like-link[disabled] {
  color: gray;
  text-decoration: none;
}

.App-sidebar {
  display: block;
  flex-basis: 20em;
  width: 20em;
  flex-grow: 1;
}

.App-page {
  flex-basis: 0;
  flex-grow: 999;
}

.App-page article {
  padding-left: 3em;
  padding-top: 1em;
}

.App-link {
  color: #61dafb;
}

.App-auth-message {
  padding: 1em;
}

.App-nav li {
  display: inline-block;
}

.App-nav .App-nav-button {
  display: inline-block;
  padding: 1em;
  background-color: #61dafb;
  line-height: 3em;
  text-decoration: none;
  color: #222;
  border: none;
  font-size: 1em;
  box-sizing: border-box;
}

.App-nav li, .App-nav-button {
  width: 220px;
}

.App-nav-button:focus {
  border: 5px double white;
}

.App-nav-auth {
  margin-top: 4em;
}

.App-nav-username {
  display: block;
  padding-bottom: 0.5em;
}

.App-search input {
  width: 220px;
  font-size: 1em;
  margin: 0;
  line-height: 2;
  border: none;
  padding: .5em;
  background: #DDD;
  text-align: center;
}

.App-order-list {
  margin: 0 0 5em;
  padding: 0;
  align-items: center;
  width: 100%;
}

.App-order-list .App-order-set:not([order-set-quantity="1"]) {
  border-left: 2.25px solid orange;
}
.App-order-list .App-order-set:not([order-set-quantity="1"]) tr:first-child td:first-child {
  border-top: 2px solid orange;
}
.App-order-list .App-order-set:not([order-set-quantity="1"]) tr:last-child td:first-child {
  border-bottom: 2px solid orange;
}

.App-order-detail output {
  display: inline-block;
  margin: 0 1em;
}

.App-order-detail .App-cancelled-at {
  background-color: red;
}

.App-order-detail .App-order-print-button {
  padding: 0.3em;
  font-size: 0.8em;
  background: white;
  border: 1px solid gray;
}

.App-open-order-button {
  min-width: 30em;
}

.App-device-monitor {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #61dafb;
  border-width: 5px 0 0 5px;
  padding: 0 0.2em;
}
.App-device-monitor h2 {
  display: inline-block;
  font-size: 1.2em;
  font-variant: small-caps;
  margin: 0 0.4em;
  padding: 0.4em 0;
}
.App-device-monitor ul {
  list-style-type: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}
.App-device-monitor ul li {
  display: inline-block;
  margin: 0 0.2em;
  font-size: 3.5em;
  text-shadow: 0 0 5px white;
  transition: filter 500ms linear;
}
.App-device-monitor li[data-transfer-status="transferring"] {
  filter: hue-rotate(208deg);
}
.App-device-monitor li[data-transfer-status="transferred"] {
  filter: hue-rotate(49deg);
}
.App-device-monitor li[data-transfer-status="error"] {
  filter: hue-rotate(360deg);
}
.App-device-monitor li[data-device-status="unmounted"] {
  filter: hue-rotate(114deg);
}

.App-order-active-button {
  background: white;
  filter: hue-rotate(341deg);
  padding-right: 3px;
  border: none;
  line-height: 0;
}

.App-order-active-button:focus {
  outline: none;
  border-color: white;
}

.App-order-detail-status-fulfilled .App-order-active-button {
  font-size: 20px;
}
.App-order-detail-status-archived .App-order-active-button {
  font-size: 20px;
}
.App-order-detail-status-paid .App-order-active-button {
  font-size: 20px;
}

.App-copy-button {
  background: white;
  border: none;
  line-height: 0.4em;
  font-size: 3em;
  padding-right: 1px;
  filter: grayscale(1);
}

.App-finalize-order-dialog {
  position: fixed;
  top: 2em;
  margin: 0 auto;
  border: 1px solid black;
}

.App-finalize-order-dialog-content {
  max-height: calc(100vh - 50px);
  overflow: auto;
}

.App-finalize-order-dialog header {
  width: 100%;
  position: relative;
}

.App-finalize-order-dialog header h1 {
  margin-top: 0em;
  padding-right: 2em;
}

.App-finalize-order-dialog output {
  display: block;
  max-width: 60em;
  line-height: 2.5;
}

.App-dialog-close-button {
  background: #61dafb;
  border: none;
  line-height: 1;
  font-size: 2em;
  margin-left: auto;
  position: absolute;
  top: -1.5em;
  right: -1.5em;
  padding: 0.6em;
  width: 2em;
  height: 2em;
}

.App-transfer-status-message {
  width: 100%;
  background-color: rgba(30, 250, 250, 0.5);
  padding: 1em;
  margin: 1em 0;
}

.App-finalize-compare-videos {
  min-height: 22em;
}
.App-finalize-compare-videos video {
  cursor: pointer;
  display: inline-block;
}
.App-finalize-compare-videos video + video {
  margin-left: 2em;
}

.App-finalize-labels button {
  font-size: 1em;
  padding: 0.7em;
  margin: 1em auto;
}

.App-finalize-labels .App-print-button,
.App-rate-selection button,
.App-finalize-rate-container button,
.App-finalize-labels-button-container button,
.App-label-controls button,
.App-finalize-button {
  background: white;
  border: 1px solid gray;
}

.App-finalize-labels button.a-dangerous-button {
  background-color: #f50808;
}

.App-finalize-labels {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(60em + 500px);
}

.App-finalize-labels h2 {
  flex: 0 0 100%;
}

.App-finalize-labels h3 {
  margin-top: 0;
}

.App-finalize-left-col {
  flex: 0 0 500px;
}

.App-finalize-right-col {
}

.App-finalize-labels-shipping, .App-finalize-labels-message, .App-finalize-labels-cover {
  margin-left: 2em;
  margin-right: 2em;
}

.App-finalize-labels-shipping {
  position: relative;
  width: 30em;
}

.App-finalize-labels-shipping ul button {
  width: 2rem;
  height: 2rem;
}

.App-real-shipping-label {
  width: 100%;
}

.App-fake-shipping-label {
  width: 100%;
  height: 471px;
  filter: blur(3px);
}

/* On cell phones */
@media only screen and (max-width: 600px) {
  .App-fake-shipping-label, .App-real-shipping-label {
    display: none;
  }
}

.App-finalize-labels-message img {
  width: 300px;
  border-radius: 10px;
  border: 1px solid black;
}

.App-finalize-labels-cover img {
  width: 300px;
  height: 300px;
}

.App-finalize-rate-container {
  position: absolute;
  top: 50%;
  width: 30em;
}

.App-finalize-labels-button-container {
  margin-bottom: 1em;
}

.App-rate-selection {
  font-size: 0.9em;
  display: block;
  margin: 2em 0 0 0;
}

.App-rate-selection select {
  font-size: inherit;
  padding: 0.5em;
  width: 100%;
  border: 1px solid gray;
}

.App-finalize-button-container {
  flex: 1;
  margin-top: 1.5em;
  background: grey;
}

.App-finalize-button-container .App-finalize-button-error {
  background: red;
  padding: 0.5em 1em;
  margin: 0.5em 0;
  display: block;
  max-width: 34em;
  line-height: 1.4;
  font-size: 1em;
}

.App-huge-down-arrow {
  font-size: 4em;
  margin-top: 0.25em;
}

.App-open-in-shopify-mini {
  width: calc(64px * 0.35);
  height: calc(64px * 0.35);
  display: inline-block;
  overflow: hidden;
}

.App-open-in-shopify-mini svg {
  transform: scale(0.35);
  transform-origin: top left;
  filter: grayscale(1);
}

.App-share-video {
  width: calc(122.88px * 0.15);
  height: calc(114.318px * 0.15);
  display: inline-block;
  overflow: hidden;
  margin-top: 2px;
}

.App-share-video svg {
  padding-top: 3px;
  transform: scale(0.15);
  transform-origin: top left;
  opacity: 0.8;
}

.App-daemon-error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.App-daemon-install-code {
    max-width: 900px;
    background-color: lightblue;
    line-height: 1.5;
}

.App-daemon-wagon {
    max-width: 300px
}

.App-load-more {
    padding: 0.5rem;
    margin: 2rem;
}

.btn {
  @apply font-bold py-2 px-4 text-center;
}
.btn-primary {
  @apply text-white;
  background-color: #61dafb;
}
.btn-danger {
  @apply bg-red-500 text-white;
}
.btn-danger[disabled] {
  @apply bg-red-200 text-white;
}

.btn-block-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: masonry;
}
.btn-block-full {
  grid-column-start: 1;
  grid-column-end:  3;
}

.react-calendar {
  font-size: 1rem;
}

.App-order-timeline-event {
  text-align: left;
  position: relative;
}
.App-order-timeline-event:before {
  width: 100%;
  position: absolute;
  top: 50%;
  border-bottom: 3px solid black;
  left: 0;
  z-index: 0;
  content: " ";
}
.App-order-timeline-header-entry {
  max-width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  visibility: hidden;
}
.App-order-timeline-header-entry.always-show {
  max-width: 100%;
  padding: auto;
  margin: auto;
  visibility: visible;
}
@media (min-width: 600px) {
  .App-order-timeline-header-entry.show-five {
    max-width: 100%;
    padding: auto;
    margin: auto;
    visibility: visible;
  }
}
@media (min-width: 800px) {
  .App-order-timeline-header-entry.show-ten {
    max-width: 100%;
    padding: auto;
    margin: auto;
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .App-order-timeline-header-entry.show-twenty {
    max-width: 100%;
    padding: auto;
    margin: auto;
    visibility: visible;
  }
}

.keyboard-shortcut-indicator {
  text-decoration: underline;
}

.vis-item {
  left: 0;
}
.vis-item .vis-item-overflow:hover {
  overflow: visible;
}
.vis-item:hover {
  z-index: 100000;
}
.vis-left {
  max-width: 30em;
}
.timeline-group-express.vis-label {
  background: cornsilk;
}
.timeline-item-estimated-delivery {
  background: #f17676 !important;
  color: white;
}
.timeline-item-actual-delivery {
  background: green !important;
  color: white;
}
.timeline-item-shipped {
  background: violet !important;
  color: white;
}
.timeline-item-requested-delivery {
  background: #D22 !important;
  color: white;
}
.timeline-item-preorder {
  background: gold !important;
}
.timeline-item-purchase {
  background: pink !important;
}
.timeline-item-designed {
  background: #22D !important;
  color: white;
}
.App-timeline-filters input:checked + label {
  border: 1px solid blue;
  background: #b0b5ec;
}
